/* User */

/*Home page styling*/
@media screen and (min-width:960px) {
    .exclusive-text-mobile {
        padding:25px;
        background-color:#ebebeb;
        font-family:montserrat;
        margin:auto;
        font-size:2em;
   }
    .exclusive-text-mobile p {
        margin-top:-10px;
   }
}
.home-heading h1{
    font-family:montserrat;
    font-weight:bold;
    color:#49256e;
    text-align:center;
}
.home-content {
    font-family:montserrat;
    font-weight:100;
    text-align:center;
}
.home-products {
    text-align:center;
    font-family:montserrat;
}
.home-products h2 {
    letter-spacing:2px;
    margin-top:4%;
}
.home-products .shop-now {
    font-family:montserrat;
    font-weight:100;
    font-size:16px;
    border:1px solid #737373;
    background-color:#fff;
    color:#000;
    padding:14px 40px;
}
.home-products .shop-now:hover {
    font-family:montserrat;
    color:#fff;
    background-color:#49256e;
    font-size:16px;
    border:none;
    padding:14px 40px;
}
/*Carousel caption*/
.carousel-caption-duo {
    position:absolute;
    top:30%;
    left:45%;
    -webkit-transform:translate(-30%, -30%);
    transform:translate(-30%, -30%);
    text-align:center;
    color:#fff;
}
.carousel-caption-duo .shop-now, .carousel-caption-keyboard .shop-now, .carousel-caption-keyboard .shop-now:hover, .carousel-images-wrapper .shop-now {
    margin-top:16px;
    padding:14px 40px;
    font-family:montserrat;
    font-weight:700;
    background-color:#49256e;
    color:#fff;
    font-size:16px;
    border:none;
}
.carousel-caption-duo .shop-now a, .carousel-caption-keyboard .shop-now a, .carousel-images-wrapper .shop-now a {
    color:#fff;
}
.carousel-caption-duo .duo-text {
    font-size:2em;
    line-height:1.5em;
    color:#fff;
}
.carousel-caption-keyboard {
    position:absolute;
    top:45%;
    left:30%;
    -webkit-transform:translate(-45%, -30%);
    transform:translate(-45%, -30%);
    text-align:left;
    color:#49256e;
    font-weight:bold;
}
.carousel-caption-keyboard .keyboard-text {
    font-size:2.5em;
    line-height:1.5em;
    color:#49256e;
}
.carousel-caption-manvac {
    position:absolute;
    top:25%;
    left:30%;
    -webkit-transform:translate(-30%, -30%);
    transform:translate(-30%, -30%);
    text-align:left;
    color:#49256e;
    margin:auto;
}
.carousel-caption-manvac .shop-now {
    margin-top:16px;
    padding:14px 40px;
    font-family:montserrat;
    font-weight:700;
    background-color:#f15a22;
    color:#fff;
    font-size:16px;
    border:none;
}
.carousel-caption-manvac h1 {
    font-size:3em;
    font-weight:bold;
    color:#49256e;
}
.carousel-caption-manvac h2 {
    font-size:1.5em;
    line-height:1.5em;
    color:#49256e;
}
.carousel-caption-manvac p {
    font-size:1.7em;
    line-height:1.4em;
}
.carousel-caption-3way {
    position:absolute;
    top:10%;
    text-align:center;
    color:#49256e;
    width:100%;
    font-weight:bold;
    font-family:montserrat;
}
.carousel-caption-3way h2 {
    font-size:3em;
    line-height:1.2em;
    color: #49256e;
}
.carousel-images-wrapper {
    width:90%;
    position:absolute;
    left:50%;
    top:30%;
    -webkit-transform:translate(-50%, 10%);
    transform:translate(-50%, 10%);
}
.carousel-images {
    width:30%;
    display:inline-block;
    margin:1%;
    background:#fff;
}
.carousel-images p {
    padding:3%;
    font-size:1.2em;
}
@media screen and (max-width:479px) {
    .carousel-caption-manvac {
        top:100%;
        left:30%;
        -webkit-transform:translate(-30%, -30%);
        transform:translate(-30%, -30%);
   }
    .carousel-caption-manvac h1 {
        font-size:1em;
   }
    .carousel-caption-manvac .shop-now {
        margin-top:14px;
        padding:14px 40px;
        font-family:montserrat;
        font-weight:700;
        background-color:#f15a22;
        color:#fff;
        font-size:16px;
        border:none;
   }
    .carousel-caption-manvac h2 {
        font-size:1em;
        line-height:1.5em;
   }
    .carousel-caption-manvac p {
        font-size:1.2em;
        line-height:1.4em;
   }
   .carousel-caption-duo .shop-now a, .carousel-caption-keyboard .shop-now a, .carousel-images-wrapper .shop-now a {
        color:#fff;
    }
    .carousel-caption-duo-mobile .shop-now, .carousel-caption-keyboard-mobile .shop-now, .carousel-images-wrapper-mobile .shop-now {
        margin-top:14px;
        padding:14px 40px;
        font-family:montserrat;
        font-weight:700;
        background-color:#49256e;
        color:#fff;
        font-size:16px;
        border:none;
    }
    .duo-clean {
        width:50% !important;
        padding-bottom: 10px;
    }
    .exclusive-text-mobile {
        padding:10px;
        background-color:#ebebeb;
        font-family:montserrat;
        margin:auto;
        font-size:1em;
    }
    .exclusive-text-mobile p {
        margin-top:-5px;
    }
    .carousel-caption-manvac-mobile {
        color:#49256e;
        text-align:center;
    }
    .carousel-caption-manvac-mobile .shop-now {
        margin-top:16px;
        padding:14px 40px;
        font-family:montserrat;
        font-weight:700;
        background-color:#f15a22;
        color:#fff;
        font-size:14px;
        border:none;
    }
    .carousel-caption-manvac-mobile h1 {
        font-size:1.5em;
        font-weight:bold;
        letter-spacing:1px;
        margin-top:2%;
        color:#49256e;
    }
    .carousel-caption-manvac-mobile h2, .carousel-caption-3way-mobile h2 {
        font-size:1.2em;
        line-height:1.2em;
        letter-spacing:2px;
        color:#49256e;
    }
    .carousel-caption-manvac-mobile p {
        font-size:1em !important;
        line-height:1.4em !important;
    }
    .carousel-caption-duo-mobile {
        text-align:center;
        color:#49256e;
    }
    .carousel-caption-duo-mobile .shop-now, .carousel-caption-keyboard-mobile .shop-now, .carousel-caption-3way-mobile .shop-now {
        margin-top:16px;
        padding:14px 40px;
        font-family:montserrat;
        font-weight:700;
        background-color:#49256e;
        color:#fff;
        font-size:14px;
        border:none;
    }
    .carousel-caption-duo-mobile .shop-now:hover {
        color:#fff;
    }
    .carousel-caption-duo-mobile .duo-text {
        font-size:1em;
        line-height:1.5em;
    }
    .carousel-caption-keyboard-mobile {
        text-align:center;
        color:#49256e;
    }
    .carousel-caption-keyboard-mobile .keyboard-text {
        font-size:1em;
        line-height:1.5em;
    }
    .carousel-caption-3way-mobile {
        text-align:center;
        color:#49256e;
        font-weight:bold;
        font-family:montserrat;
    }
}

/* END Home page styling*/