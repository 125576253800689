@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
    color: #000;
}
h1 {
	font-size:40px;
	letter-spacing:0px;
	text-transform:Inherit;
	font-family: 'Open Sans', sans-serif;
	font-weight:400;
	color:#0443F2;
}
h2 {
	font-size:30px;
	font-weight:400;
	letter-spacing:0;
	text-transform: none !important;
	line-height:30px;
	font-family: 'Open Sans', sans-serif;
}
h3 {
	font-size:20px;
	font-weight:400;
	line-height:inherit;
	font-family: 'Open Sans', sans-serif;
}
h4 {
	font-weight:400;
	font-size:20px;
	font-family: 'Open Sans', sans-serif;
}
h6 {
	font-size:20px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Open Sans', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 16px;
	color: #fff;
	font-family: 'Open Sans', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#0443F2;
	box-shadow:none;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}
/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border: 1px soild rgba(212, 212, 212, 0.47);
	color: #000;
	font-family: 'Open Sans', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #000;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
    opacity:0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #000;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}
.nav-regular li.level0 > a > span {
    color:#fff; 
    font-size:16px;
    font-weight: 600;
}
.links > li > a:hover {
	background-color: #0443F2;
	color: #fff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #0443F2;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover, .nav-regular .nav-item.level0.active>a {
	background-color: transparent;
	color: #fff;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #0443F2;
}
#nav .nav-panel--dropdown a {
	color: #0443F2;
	font-size: 16px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 16px;
}
#nav .nav-panel--dropdown a:hover {
	color: #0443F2;
}
.mobnav-trigger.active {
	background-color: #0443F2;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: #0443F2;
	color: #fff;
}
#nav .nav-item--home .fa-home {
	color: #0443F2 !important;
	padding: 0.3rem;
}
#nav .nav-item--home {
    display:none;
}
#root-wrapper a:hover span.fa:before {
	color: #fff;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #fff;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #fff;
	border-right-color: #fff;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #0443F2 !important;
	padding: 0;
	border-radius: 6px;
	max-height: 35px;
	font-size: 18px;
	margin-top: 10px;
    
}
#mini-cart .actions button.button span span:hover {
	background-color: #fff !important;
    color:#0443F2;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 16px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: inherit;
    font-weight: 600;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 40px;
	color: #0443F2;
	letter-spacing: 0px;
	text-transform: inherit;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
    min-height: 60px;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 20px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #0443F2;
	border-radius: 6px;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
}
button.button span {
	font-size: 18px;
}
button.button span span {
	padding: 0 24px;
    color:#fff;
    height:35px;
    line-height:35px;
    border: 1px solid #0443F2;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #fff !important;
    color:#0443F2 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 20px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: rgba(212, 212, 212, 0.47);
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #0443F2;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #0443F2;
}
.footer-top-container {
	padding-top: 10px;
    background-color: #0443F2;
}
.footer-primary-container {
	background-color: #0443F2;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #fff;
	color: #000;
}


/************************** MICS **************************/

/*Footer*/
.footer-top .section {
     width:100%;
    padding:0;
}
.footer-copyright {
     margin:0;
     font-weight:600;
     font-size:14px;
}
.footer-bottom-container .section {
     text-align:center;
     padding:5px 0;
}
.footer-bottom-container .item-left {
     float:none;
}
.outer-footer {
     display:flex;
     justify-content:space-between;
     margin:2% 0 1% 0;
}
.links-footer > *, .links-footer a:hover {
     color:#fff;
}
.links-footer, .social-icons {
     display:flex;
}
.links-footer > a {
     line-height:33px;
}
.links-footer > span {
     margin:0 15px;
     line-height:33px;
}
.social-icons {
     width:15%;
     justify-content:space-between;
}
#scroll-to-top {
     display:none !important;
}
@media only screen and (max-width:1599px) {
     .social-icons img {
         width:80%;
    }
}
@media only screen and (max-width:1259px) {
     .social-icons img {
         width:70%;
    }
}
@media only screen and (max-width:959px) {
     .social-icons {
         width:18%;
    }
}
@media only screen and (max-width:767px) {
     .footer-copyright {
         font-size:12px;
    }
     .outer-footer {
         flex-direction:column-reverse;
    }
     .links-footer {
         width:100%;
         margin:auto;
         justify-content:center;
         margin-left:-15px;
         margin-top:10px;
    }
     .social-icons {
         width:50%;
         margin:auto;
         padding-left:5%;
    }
     .outer-footer {
         margin-top:20px;
         margin-bottom:10px;
    }
     .links-footer > a {
         font-size:14px;
    }
     .links-footer > span {
         margin:0 10px;
    }
}

/*Homepage*/
.homepage .col-main.grid-full {
     width:100%;
     margin:0 !important;
}
.brands-logos .owl-controls {
     display:none;
}
.homepage .brands-logos img {
     max-width:100px !important;
}
.homepage .brands-logos .owl-item {
     height:auto !important;
}

@media only screen and (max-width:767px) {
     .homepage .top-banner {
         margin-bottom:0 !important;
    }
}

/*category pages*/
.product-search-container {
     width:100%;
     margin-left:0;
     margin-right:0;
}
.sidebar-wrapper.module-category-filters .block-title span {
     text-transform:Capitalize;
}
.sidebar-wrapper.module-category-filters .block-title, .accordion-style1 li a {
     border:none;
}
.product-listing {
     margin-top:0 !important;
}
.pager {
     border:none;
}
.pager .pages li a{
     background-color:#D4D4D4;
     color:#000;
}
.pager .pages li.current, .pager .pages li a:hover {
     background-color:#fff;
     color:#000;
}
.module-category-misc .sorter {
     margin:10px 0;
}
.product-listing h2.product-name a{
     color:#000;
     font-weight:600;
}
.product-listing .price-box {
     font-weight:400; 
    font-size:20px;
    padding-top: 10px;
}
.category-filters {
     margin-top:0;
}
.category-filters .mmenu-target-categories{
     border-radius:13px;
     background-color:#fff;
}
.category-filters .mmenu-target-categories .block-title, .category-filters .mmenu-target-categories .block-content li a {
     color:#000;
     font-weight:600;
}
.category-filters .mmenu-target-categories .block-title {
     padding-bottom:15px;
     border-bottom:none;
     width:80%;
     margin:0 auto;
}
.category-filters .mmenu-target-categories .block-content {
     width:80%;
     margin:0 auto;
}
.category-filters .mmenu-target-categories .block-content li {
     margin-bottom:8px;
}
.category-products-listing.products-list .item .button.more-info {
    display:block;
}
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:16px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
     .menu-icon.fa.fa-sliders {
         font-size:18px !important;
         padding-top: 0 !important;
             padding-bottom: 0 !important;
    }
     .mobile-filters {
         margin-top:15px;
         float:right !important;
    }
    .mobile-filters .fa-sliders:before {
    font-size: 16px;
    }
}
@media only screen and (max-width:767px) {
     .products-grid .item button.button span span {
         padding:0px !important;
    }
}
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
.category-products-grid .item {
     border:none;
     margin-bottom:20px;
}
.category-products-grid .item .qty-box {
     height:35px;
     margin-bottom:0px;
}
.grid-full.breadcrumbs {
     margin-top:2%;
}
.sorter .view-mode a:hover {
     background-color:#052F67;
}
.volume_price_div {
     margin-top:7px;
}
#breadcrumbs li.last_child > span {
     vertical-align:super;
}
.toolbar-section .module-category-misc {
     display:flex;
     justify-content:space-between;
     flex-wrap:wrap;
}
.toolbar-section .module-category-misc > div {
     margin:auto;
}
.category-products-grid .item {
     padding-bottom:90px !important;
}
@media only screen and (max-width:767px) {
     .category-products-grid .item {
         padding-bottom:150px !important;
    }
}
.item .module-category-product-listing__productImage {
     border:1px solid #cfcfcf;
     border-radius:6px;
}
.category-filters {
     padding-top:4%;
}
@media only screen and (min-width:768px) {
     .item .module-category-product-listing .add-cart-wrapper {
         display:flex;
         justify-content:center;
    }
     .item .module-category-product-listing .add-cart-wrapper > .qty-wrapper{
         margin:auto 10px;
    }
     .item .module-category-product-listing .add-cart-wrapper > button{
         margin:auto;
         margin-left:10px;
    }
}
.module-category-product-listing .add-cart-wrapper input.qty {
     height:35px;
     border:1px solid #0443f2;
     border-radius:6px;
}
.products-line .module-category-product-listing .module-category-product-listing__actionButtons .qty-wrapper input.qty {
     width:3em !important;
}
.item .add-cart-wrapper button.button span span {
     padding:0 6px;
}
.module-category-product-listing__productDesc span.item-code, .module-category-product-listing__productDesc span.item-code-label, .item .price-box .price span, .item .price-box .price, .item .price-box, .item .price-box span, .item .price-box div {
     font-size:15px;
}
@media only screen and (max-width:1535px) {
     .module-category-product-listing__productDesc span.item-code, .module-category-product-listing__productDesc span.item-code-label, .item .price-box .price span, .item .price-box .price, .item .price-box, .item .price-box span, .item .price-box div {
         font-size:14px;
    }
}
@media only screen and (max-width:1365px) {
     .module-category-product-listing__productDesc span.item-code, .module-category-product-listing__productDesc span.item-code-label, .item .price-box .price span, .item .price-box .price, .item .price-box, .item .price-box span, .item .price-box div {
         font-size:13px;
    }
}
.mobile-filters .fa-sliders:before {
     content:"Filter:" !important;
     font-family:"Open Sans";
}
.mobile-filters .fa-sliders:after {
     content:"\f1de" !important;
     margin-left:5px;
}

/*Responsive content*/
.module-sub-categories > div {
     text-align:center;
     margin-bottom:40px;
}
.module-sub-categories > div .module-sub-categories-img {
     max-width:200px;
     margin:auto;
}
.breadcrumb-separator {
     background:none !important;
     text-indent:0 !important;
     overflow:unset !important;
     margin:0 !important;
     font-size:16px !important;
     width:auto !important;
     margin-top:2px !important;
}

/*Header:starts*/
.header-top-container {
     background-color:#0443F2;
     box-shadow:none;
}
.xm-grid-header .top-header-container .nested-container, .desktop-top-header .nested-container {
     margin:0 auto;
}

.header-top-container .module-header-multi, .header-top-container .module-header-multi .item-welcome-msg {
     margin-left:0;
}
.header-top-container .module-header-multi {
     margin-right:0;
     width:100%;
}
/*Align the text on the top header*/
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a {
     line-height:40px;
     font-size:16px;
}
.top-header-container .module-header-multi p {
     margin-bottom:0;
     line-height:40px;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .links > li > a {
     color:#fff;
     font-family:"Open Sans";
     line-height:40px;
     text-transform: uppercase;
     padding-left:0;
     font-size:14px;
}
.top-header-container .links > li:not(:first-child) > a:after {
     content:"|";
     margin-left:0.5833em;
}
.module-user-links .links .link-account::before {
     content:"My";
}
.module-user-links .links>li>a:before{
     font-family:"Open Sans";
     margin-right:3px;
}
.top-header-container .links {
     display:flex;
}
.top-header-container .links > li:nth-child(1) {
     order:3;
}
.top-header-container .links > li:nth-child(2) {
     order:2;
}
.top-header-container .links > li:nth-child(3) {
     order:1;
}
.userSwitch a:hover {
    color: #fff;
    
}

/*Logo*/
.desktop-top-header .logo-column {
     margin-left:0 !important;
}
/*Menu*/
.desktop-top-header .module-category-menu .nav-item.nav-item--home {
     display:none;
}
.desktop-top-header .grid-container, .desktop-top-header ul#nav {
     display:flex;
}
.desktop-top-header .grid-container > div {
     margin:auto;
}
.desktop-top-header ul#nav > li.level0 {
     margin:0 auto;
}
.desktop-top-header .nav-regular li.level0>a {
     text-transform:inherit;
}
#nav .nav-item.level0.parent>a .caret {
     border-top-color:#fff !important;
}
.desktop-top-header .nav-regular li.level0>a {
    padding: 0px;
}
/*search*/
.desktop-top-header .module-search .form-search .button-search .fa{
     color:#000 !important;
     padding: 8px !important;
     opacity: 0.5;
}
.desktop-top-header .module-search .form-search input::placeholder, .desktop-top-header .module-search .form-search .input-text {
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45)!important;
    text-align: center;
}
.desktop-top-header .module-search .form-search .input-text {
     border:none;
     border-radius:6px;
         height: 30px;
    line-height: 30px;
}
/*cart*/
#mini-cart {
     float:left;
}
.desktop-top-header .cart-column .fa-shopping-cart {
     font-size:22px;
     color: #fff;
     display:none;
}

.desktop-top-header .cart-column #mini-cart .empty span.price{
         padding-right:0
    }
.desktop-top-header .dropdown-toggle.cover > .feature-icon-hover {
     padding:0px;
}
.desktop-top-header #mini-cart .empty .price span {
     color:#fff;
     font-size:14px;
}
#mini-cart:hover .empty .price span, #mini-cart:hover .fa-shopping-cart{
     color:#000 !important;
}
#mini-cart:hover .caret {
     border-top-color:#000 !important;
}

/*Change the text 'Switch Customer' to a FA icon for small width devices.*/
@media only screen and (max-width:1199px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
/*Hide-below-960/hide-above-960 doesn't work in all cases, so add the code but not for the mini cart*/
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
/*Change the account menu icon for mobile/tablet*/
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#fff;
}
/*Sticky header*/
#header {
     position:sticky;
     top:0;
     z-index:50;
}
/*Set all fa icons to be normal style*/
.std i {
     font-style:normal;
}
/*smaller desktops*/
@media only screen and (min-width:1366px) and (max-width:1599px) {
     .desktop-top-header .logo-column img {
         max-width:200px;
    }
     .desktop-top-header .search-column {
         width:20%;
    }
     .desktop-top-header .cart-column {
         width:11%;
    }
}
@media only screen and (min-width:1200px) and (max-width:1365px) {
    .desktop-top-header .logo-column img {
         max-width:200px;
    }
    .desktop-top-header .search-column {
         width:20%;
    }
     .desktop-top-header .cart-column {
         width:11%;
    }
     .desktop-top-header .nav-regular li.level0 > a > span, .top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
         font-size:13px;
    }
     .top-header-container .links > li > a {
         font-size:12px;
    }
    
     .desktop-top-header .module-search .form-search input::placeholder, .desktop-top-header .module-search .form-search .input-text {
         font-size:12px;
    }
}
@media only screen and (min-width:960px) and (max-width:1199px) {
     .desktop-top-header .logo-column img {
         max-width:150px;
    }
     .desktop-top-header .nav-regular li.level0 > a > span, .top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
         font-size:12px;     
         line-height: 42px;

    }
     .top-header-container .links > li > a {
         font-size:11px;
    }
    
     .desktop-top-header #mini-cart .empty .price span {
         color:#fff;
         font-size:12px;
    }
     .desktop-top-header .category-column {
         width:52.33%;
    }
     .desktop-top-header .search-column {
         width:14.66%;
    }
    .desktop-top-header .cart-column {
         width:12%;
    }
    .desktop-top-header .cart-column img {
         width:27px;
    }
}
/*Header for tablet*/
@media only screen and (min-width:768px) and (max-width:959px) {
     .desktop-top-header .category-column {
         width:20%;
    }
     .desktop-top-header .search-column {
         width:26%;
    }
     .desktop-top-header .cart-column {
         width:18%;
    }
     .desktop-top-header {
         overflow:unset;
    }
     .fa.fa-bars.userCode {
         vertical-align:inherit;
    }
     .desktop-top-header, .desktop-top-header .search-column {
         overflow:unset;
    }
    .desktop-top-header .cart-column #mini-cart .empty {
     display: inherit!important;
}
    .desktop-top-header .module-search .form-search .button-search .fa {
    padding: 6px !important;
}
    .desktop-top-header .logo-column img {
         max-width:150px;
    }
    .isPublic .desktop-top-header .cart-column {
         display:none;
    }
     .isPublic .desktop-top-header .search-column {
         margin-right:0;
    }
}
/*Inline search results*/
 #inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:300%;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:768px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
    }
}
/*Header for Mobile*/
@media only screen and (max-width:767px){
     .mobile-header .fa, .mobile-header .fa-vcard:after{
         color:#fff !important;
         font-size:22px !important;
    }
     .mobile-header{
         margin-bottom:0;
    }
     .header .logo {
         width:inherit !important;
    }
    .header .logo-wrapper {
       margin: 5px 0;
     }
     .mobile-header .header-top-container {
         background-color:#0443F2;
    }
     .mobile-header .grid-container .grid-full{
         display:flex;
         justify-content:center;
    }
     .mobile-header .grid-full > div {
         margin:auto;
    }
     .mobile-header .logo img {
         max-width:150px;
    }
     .mobile-header .form-search .button-search .fa {
         color:#fff;
    }
     .mobile-header .fa.fa-shopping-cart {
         top:-1px;
         left:0;
         display: none;
    }
    .mobile-header #mini-cart img {
            margin-top: 6px;
    }
     .mobile-header .feature-icon-hover .empty {
         display:none;
    }
     .mobile-header .fa.fa-bars, .mobile-header .fa.fa-search, .mobile-header .fa.fa-times, .mobile-header .fa.fa-vcard {
         padding-top:5px !important;
    }
     /*Style the search icon for the theme*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .item-search.fly-out .form-search .fa {
         font-size:16px;
         color:#000 !important;
         padding:10px;
         font-style:normal;
    }
}

/*Header:ends*/
/*Misc*/
.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul:not(.add-to-links) {
     list-style:disc;
     margin-left:25px;
}
ul.form-list  li.fields {
     margin-bottom:15px;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}
.isPublic .hide-for-B2C {
     display:none;
}
body:not(.isPublic) .hide-for-B2B {
     display:none;
}
input.input-text, select, textarea {
     border-color:#000;
     font-family:'Open Sans', sans-serif;
     font-size:15px;
     color:#000 !important;
     border-radius:6px;
}
input.input-text[disabled=disabled], input[disabled=disabled], input.input-text[disabled=disabled]::placeholder, input[disabled=disabled]::placeholder {
     color:#000 !important;
     -webkit-text-fill-color:rgba(0, 0, 0, 0.8);
     -webkit-opacity:0.8;
}

/**/
span.item-secondary-heading {
     display:none!important;
}
.cart-action-buttons button {
     margin:20px 10px;
}
.cart .totals {
     background-color:rgba(212, 212, 212, 0.47);
}
 .cart-summary-col {
     float:right;
}
@media only screen and (min-width:960px){
     .cart-action-buttons .button.btn-update.btn-save-change {
         float:right;
    }
}
 .cart-summary-col, .cart-promo-col {
     margin-top:30px;
}
@media only screen and (max-width:767px) {
     #shopping-cart-table {
         overflow:hidden;
    }
     .cart-action-buttons button, .cart-promo-col button {
         margin:10px;
    }
}
@media only screen and (min-width:1024px){
     .cart-summary-col, .cart-promo-col {
         width:40%;
    }
}
@media only screen and (min-width:1600px){
     .cart-summary-col, .cart-promo-col {
         width:30%;
    }
}
@media only screen and (max-width:767px) {
     .checkout-multishipping-shipping .rTableCell {
         width:95%;
    }
     .checkout-multishipping-shipping .search-box .search-btn-quickorder {
         top:30px;
    }
}
.checkout-multishipping-shipping .fa.fa-trash:before {
     color:#fff;
}
body.cart .data-table:not(.cart-table) .col-img img {
     width:60%;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .opc-main-container.onepage-checkout-main-container, .in-sidebar.opc-progress-container {
         width:98%;
    }
}
@media only screen and (max-width:767px) {
     body.cart .data-table tfoot td {
         display:inline-block !important;
         width:30% !important;
         min-width:100px;
         max-width:140px;
         margin-right:10px;
         font-weight:700;
    }
}
#shopping-cart-table h2.product-name .item-code {
     display:none;
}

/**/

.progress, div[class^=strength_meter_passwd] {
     top:25px !important;
}
.sidebar.my-account-sidebar.mmenu-hide {
     margin-bottom:40px;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu {
     display:flex;
     justify-content:space-between;
     width:100%;
     background-color:#fff;
     padding-left:0 !important;
     list-style:none;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li {
     margin:0;
     border:none;
     border-radius:6px;
     padding:0px 20px;
     line-height:35px;
     height:35px;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li.selected-navitem, .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover {
     background-color:#0443F2 ;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li.selected-navitem > a, .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover > a{
     color:#fff;
}
.sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
     padding:0;
     font-size:18px;
     color:#000;
     text-transform:uppercase;
     line-height:35px;
     height:35px;
     transition:color 0.0s ease;
}
@media only screen and (max-width:1259px) {
     .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a {
         font-size:16px;
    }
}
.account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
#order-table, #transhistory-table, #backorder-table{
     margin-top:20px;
}
 #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child a{
     display:block;
     width:90px;
     text-align:center;
}
 #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child span{
     display:none;
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
.buttons-set.module-order-details-actions .back-link {
     margin-bottom:1px;
}
.buttons-set.module-order-details-actions .fa-angle-double-left:before {
     content:" " !important;
}
.order-info-box h2 {
     font-size:26px !important;
     line-height:30px;
}
.order-info-box .box-content {
     margin-top:25px;
}
@media only screen and (min-width:960px) {
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping, .order-info-box.module-shipment-details-billing, .order-info-box.module-trans-details-shipping, .billing-module, .shipping-module{
         min-height:340px;
    }
     .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:300px;
    }
     .module-order-details-misc > div {
         margin-left:5px;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
.account-view a.button span span {
     padding:0 12px;
     font-size:16px;
     font-weight:500;
     height:35px!important;
     line-height:35px!important;
     display:inline-block;
     border:1px solid #0443F2;
}
.account-view a.button span {
     background-color:#0443F2 ;
     color:#fff;
     border-radius:6px;
}
.po-invc .pager .amount {
     padding-top:0;
}
.po-invc #form_filter_user_data input, .po-invc #form_filter_user_data button {
     margin-left:10px;
}
.po-invc .buttons-set p.back-link {
     display:none;
}
@media only screen and (max-width:767px) {
     .po-invc .my-account .pager p.amount, .po-invc .filter_order_search_label, .po-invc .my-account > p, #user_promotions_list td {
         font-size:12px !important;
    }
     .po-invc #form_filter_user_data input {
         width:100px;
    }
     .po-invc .my-account .pager p.amount {
         padding-left:5px;
         float:left;
    }
     .po-invc button.button span {
         font-size:16px;
    }
     #my-invoices-table, #my-orders-table {
         overflow:hidden;
    }
     .button.discount_button span span {
         padding:0 6px;
         font-size:14px;
    }
     #user_promotions_list tr td:last-child {
         float:right;
    }
     #user_promotions_list .cell-label {
         display:none;
    }
}
.module-address-book-default-change {
     margin:20px 0;
}
/*Login section*/
.login-page-title .page-title h1 {
     text-align:center;
}
.login-page-title .page-title .company-name {
     display:none;
}
.login-section h2, .register-section h2 {
     margin-bottom:15px;
     color:#0443F2 ;
     border-bottom:1px solid #000;
     line-height:34px;
     font-size:22px;
     padding-bottom:15px;
}
.login-section ul.form-list li {
     margin-bottom:15px;
}
.login-section ul.form-list li label {
     margin-bottom:7px;
}
.login-section p.required {
     display:none;
}
.login-section .buttons-set {
     display:flex;
     flex-direction:column-reverse;
     margin-left:0;
}
.login-section .buttons-set > a{
     margin:15px 0;
}
@media only screen and (min-width:960px) {
    .login-section.grid12-6, .register-section.grid12-6{
         width:38%;
    }
    .login-register-form .nested-container .grid-container {
         display:flex;
         justify-content:space-between;
    }
}
@media only screen and (min-width:1280px) {
     
     .login-section.grid12-6, .register-section.grid12-6{
         width:30%;
    }
}
@media only screen and (min-width:1536px) {
     .login-register-form .nested-container {
         width:85%;
    }
}
@media only screen and (min-width:1800px) {
     .login-register-form .nested-container {
         width:70%;
    }
}
@media only screen and (max-width:959px) {
    .login-register-form .nested-container, .login-page-title .nested-container{
        width:100%;
    }
     .login-section.grid12-6, .register-section.grid12-6{
         width:98%;
    }
    .login-section {
        margin-top:40px;
    }
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
     .login-section h2, .register-section h2 {
         font-size:20px;
    }
     .select2.select2-container.select2-container--default {
         width:100% !important;
         margin-bottom:15px;
    }
}
/*Transaction details*/
.page-title.module-trans-details-status h1 span, .page-title.module-order-details-status h1 {
     font-size:36px;
     line-height:40px;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie {
     padding:10px;
     background-color:#0443F2 ;
     cursor:pointer;
     color:#fff;
     border-radius:6px;
     border:1px solid #0443F2;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie:hover {
     background-color:#fff ;
     color:#0443F2;
}
.module-trans-details-misc .item-options dt {
     height:24px;
     line-height:24px;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:70px;
     border:1px solid #0443F2;
     padding-left:5px;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .xm-grid-account .grid-container-wrapper {
         overflow:unset;
    }
     .fa-bars:after {
         color:#fff !important;
    }
}
@media only screen and (max-width:959px) {
     .page-title.module-trans-details-status h1 span {
         font-size:32px !important;
    }
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable p, .ReactTable span{
         font-size:12px !important;
    }
     .user-grid-content {
         margin-top:5% !important;
    }
}
.due-input, .module-trans-details-actions .form-control {
     font-size:16px;
}
/*New Account form*/
/*Form*/
.new-account-col .grid-container > .grid-column-wrapper {
     margin-top:30px;
}
.new-account-col .grid-container {
     display:flex;
     justify-content:center;
}
.new-account-col ul.form-list > div.fieldset{
     padding-left:0;
}
@media only screen and (min-width:1200px) {
     .new-account-col ul.form-list > div.fieldset{
         display:grid;
         grid-template-columns:repeat(2, 1fr);
         gap:10px 40px;
    }
}
@media only screen and (min-width:768px) {
     .new-account-col ul.form-list li.fields.module_form_input {
         display:flex;
         margin-bottom:15px;
    }
     .new-account-col ul.form-list li.fields.module_form_input > * {
         margin:auto 0;
         width:50%;
    }
}
.new-account-col ul.form-list li.fields.module_form_input:nth-child(7) > label:after, .new-account-col ul.form-list li.fields.module_form_input:nth-child(22) > label:after, .new-account-col ul.form-list li.fields.module_form_input:nth-child(23) > label:after {
     content:"*";
     color:#ff0000;
}
.new-account-col ul.form-list li.fields.module_form_input > label em {
     display:none;
}
.new-account-col ul.form-list li.fields.module_form_input input.input-text, .new-account-col ul.form-list li.fields.module_form_input select, .new-account-col ul.form-list li.fields.module_form_input textarea {
     border-radius:0px;
     padding:0 8px;
}
.new-account-col ul.form-list li.fields.module_form_input input.input-text, .new-account-col ul.form-list li.fields.module_form_input select{
     height:32px;
     line-height:32px;
}
@media only screen and (min-width:1800px) {
     .new-account-col .grid-container > .grid-column-wrapper {
         width:81.33%;
    }
}
@media only screen and (min-width:960px) and (max-width:1279px) {
     .new-account-col ul.form-list li.fields.module_form_input > * {
         font-size:14px;
    }
}
@media only screen and (max-width:767px) {
     .new-acnt-title h1 span {
         font-size:20px !important;
    }
}